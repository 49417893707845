import { graphql, useStaticQuery, Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import React, { Fragment } from "react"
import { Row, Col, Container } from "react-bootstrap"
import { v4 as uuidv4 } from "uuid"

import * as style from "../productCategory/ProductCategoryHeader.module.scss"
import { getProductLink, selectFirstVariant } from "../../../utils"

const BrandMarketingContentHeader = ({ headerContent }) => {

  const createBodyContent = {
    ContentFields: renderContentFields,
    ThreeBoxesInRow: renderThreeBoxesInRow,
    InfoBanner: renderInfoBanner,
  }
  const body = []

    headerContent.forEach(item => {
      const headerContentTypeName = item.fieldGroupName?.replace(
        /PaBrand_Productcategoryheader_HeaderContent_/,
        ""
      )

      if (!createBodyContent[headerContentTypeName]) {
        console.error(
          `Yikes! Unknown render method! fieldGroupName: ${item.fieldGroupName}; headerContentTypeName: ${headerContentTypeName}`
        )
        return null
      }

      body.push(createBodyContent[headerContentTypeName](item))

      return null
    })

  return (
    <>
      {body.map(item => (
        <Fragment key={uuidv4()}>{item}</Fragment>
      ))}
    </>
  )
}

const renderContentFields = item => {
  const { title, description, shortLinks } = item

  return (
    <Container className="product-category-header content-feilds">
      <Row className="py-4">
        <Col lg={0} className="col-xxl-2 d-none d-lg-block" />
        <Col lg={12} className="col-xxl-8">
          {title && (
            <>
              <div className="mx-auto my-large text-center">
                <h2 className="mb-3 text-uppercase">{title}</h2>
                {description && (
                  <div dangerouslySetInnerHTML={{ __html: description }} />
                )}
              </div>
              <Row className={style.shortLinkRow}>
                {shortLinks &&
                  shortLinks.length &&
                  shortLinks.map(item => {
                    const { label, slug } = item

                    return (
                      <Col
                        md={6}
                        lg={3}
                        className={style.shortLinkCol}
                        key={uuidv4()}>
                        <Link className={style.link} to={slug}>
                          <GatsbyImage
                            image={getImage(item.image.localFile)}
                            loading="lazy"
                            className={style.image}
                            alt={item.image.altText}
                          />
                          <div className={style.linkText}>{label}</div>
                        </Link>
                      </Col>
                    )
                  })}
              </Row>
            </>
          )}
        </Col>
      </Row>
    </Container>
  )
}

const renderThreeBoxesInRow = item => {
  const { boxes } = item
  return (
    <Container
      fluid={true}
      className="product-category-header three-boxes-in-row">
      <Row>
        {boxes.map((item, index) => {
          const {
            box: { discountCode, icon, text, pageLink },
          } = item
          return (
            <Col key={index} sm={12} md={index === 2 ? 12 : 6} xl={4}>
              <div
                className={`d-flex flex-row justify-content-center align-items-center h-100 `}>
                {icon && icon.image && icon.position === "left" && (
                  <GatsbyImage
                    image={getImage(icon.image.localFile)}
                    loading="lazy"
                    className="ml-3"
                    alt={icon.image.altText}
                  />
                )}
                {(!discountCode || !isCodeValid(discountCode.dateExpiry)) && (
                  <span className={style.threeBoxesInRowText}>{text}</span>
                )}
                {discountCode && isCodeValid(discountCode.dateExpiry) && (
                  <p className={style.threeBoxesInRowText}>
                    {discountCode.description}&nbsp;
                    <span className={style.threeBoxesInRowDiscount}>
                      code&nbsp;{discountCode.code}
                    </span>
                  </p>
                )}
                {icon && icon.image && icon.position === "right" && (
                  <GatsbyImage
                    image={getImage(icon.image.localFile)}
                    loading="lazy"
                    className="ml-3"
                    alt={icon.image.altText}
                  />
                )}
                {pageLink && pageLink.url && (
                  <a href={pageLink.url} className={style.threeBoxesInRowLink}>
                    {pageLink.title}
                  </a>
                )}
              </div>
            </Col>
          )
        })}
      </Row>
    </Container>
  )
}

const renderInfoBanner = item => {
  const { leftSideImage, rightSideInfo } = item

  let selectedProduct

  const { product } = rightSideInfo

  selectedProduct = selectFirstVariant(product)

  return (
    <Container
      fluid={true}
      className="product-category-header info-banner mx-0 px-0">
      <Row noGutters={true}>
        <Col md={6}>
          <GatsbyImage
            image={getImage(leftSideImage.localFile)}
            loading="lazy"
            alt={leftSideImage.altText}
          />
        </Col>
        <Col md={6} className={style.infoBannerRightSide}>
          <div className="d-flex flex-column justify-content-center align-items-center h-100 position-relative">
            {rightSideInfo.text && (
              <div
                className={style.infoBannerText}
                dangerouslySetInnerHTML={{ __html: rightSideInfo.text }}
              />
            )}
            {selectedProduct && (
              <p>
                <a
                  role="button"
                  className={style.infoBannerButton}
                  href={getProductLink(selectedProduct)}>
                  Check it out
                </a>
              </p>
            )}
            <div className={style.infoBannerLeftBottomCorner} />
            <div className={style.infoBannerRightTopCorner} />
          </div>
        </Col>
      </Row>
    </Container>
  )
}

const isCodeValid = dateExpiry => {
  if (dateExpiry === null) {
    return true
  }
  const currentDate = new Date()
  const codeDate = Date.parse(dateExpiry.substring(0, 19))

  return currentDate < codeDate
}

export default BrandMarketingContentHeader
