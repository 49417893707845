import React from "react"
import loadable from "@loadable/component"

import BrandMarketingContentHeader from "./BrandMarketingContentHeader"
import FilteredProductsList from "../FilteredProductsList"
import FilterMobileMenu from "../FilterMobileMenu"
import { DEFAULT_PADDING, DEFAULT_END_SLIDER_PADDING } from "../../../utils/acfComponentHelper"
import * as style from "./brand.module.scss"
import Breadcrumb from "../../../components/UI/Breadcrumb/Breadcrumb"
const HeaderWithTaglineOption = loadable(() =>
  import("../../../components/AcfPageBlocks/HeaderWithTaglineOption")
)
const TextPanelWithTwoBkgdImgs = loadable(() =>
  import("../../../components/AcfPageBlocks/TextPanelWithTwoBkgdImgs")
)
const Faq = loadable(() => import("../../../components/AcfPageBlocks/Faq"))
const ThreePostsRow = loadable(() =>
  import("../../../components/AcfPageBlocks/ThreePostsRow")
)
const BrandsCarouselVariant2 = loadable(() =>
  import("../../../components/AcfPageBlocks/BrandsCarouselVariant2")
)

const BrandView = ({
  location,
  products,
  data,
  pageContext,
  breadcrumbData,
  Filters,
  mobileToggle,
  toggleMenu,
  handleClearFilters,
  handlePageChange,
  handleSelectSort,
  CustomToggle,
  getDynamicProductLink,
  acfData,
}) => {

  const {
      wpPaBrand: {
        id,
        name,
        slug,
        link,
        productCategoryHeader: {
          headerContent
        },
        extendedBrandInfo: {
          seoDescription,
          mainDescription,
          featureList,
          largeImage,
        },
        productBrandPageLayouts: {
          brandLayoutsData: {
            header,
            helpCallToAction,
            faq,
            postRecommendations,
            moreBrandsSlider,
          },
        },
      },
      allWpPost,
      allWpPaBrand,
    helpCtaImg1,
    helpCtaImg2,
  } = acfData

  const defaultHelpCtaImgs = [
    {
      image: { altText: `${name} background image 1`, localFile: helpCtaImg1 },
    },
    {
      image: { altText: `${name} background image 2`, localFile: helpCtaImg2 },
    },
  ]
  const defaultMoreBrands = allWpPaBrand.nodes.filter(brand => brand.id !== id && brand.extendedBrandInfo.logo !== null)
  const tags = featureList?.map(item => {
    return { tag: item.listItem }
  })

  const isMarketingPresent = acfData.wpPaBrand?.productCategoryHeader?.headerContent && acfData.wpPaBrand.productCategoryHeader?.headerContent.length > 0

  return (
    <>
      {mobileToggle && (
        <FilterMobileMenu
          Filters={Filters}
          toggleMenu={toggleMenu}
          handleClearFilters={handleClearFilters}
        />
      )}
      <Breadcrumb data={breadcrumbData} />
      {mainDescription && !isMarketingPresent ? (
        <HeaderWithTaglineOption
          isBrandPage
          image={largeImage}
          title={name}
          description={mainDescription}
          subTitle={header.subTitle}
          subDescription={header.subDescription}
          tags={tags}
        />
      ) : mainDescription ? <></> : <h1 className="my-5 text-uppercase text-center">{name}</h1>}
      {
        isMarketingPresent ? <BrandMarketingContentHeader headerContent={acfData.wpPaBrand.productCategoryHeader?.headerContent}/> : <></>
      }
      <FilteredProductsList
        location={location}
        data={data}
        breadcrumbData={breadcrumbData}
        pageContext={pageContext}
        products={products}
        Filters={Filters}
        toggleMenu={toggleMenu}
        CustomToggle={CustomToggle}
        handleSelectSort={handleSelectSort}
        handleClearFilters={handleClearFilters}
        handlePageChange={handlePageChange}
        getDynamicProductLink={getDynamicProductLink}
      />


      {mainDescription && isMarketingPresent ? (
              <HeaderWithTaglineOption
                  isBrandPage
                  image={largeImage}
                  title={name}
                  description={mainDescription}
                  subTitle={header.subTitle}
                  subDescription={header.subDescription}
                  tags={tags}
              />
          ) : <></>
      }

      {helpCallToAction.button && <TextPanelWithTwoBkgdImgs
        imageBoxes={helpCallToAction.imageBoxes || defaultHelpCtaImgs}
        sectionHeading={helpCallToAction.sectionHeading}
        text={helpCallToAction.text}
        button={helpCallToAction.button}
        config={DEFAULT_PADDING}
      />}

      {faq.accordion && faq.accordion.length && <Faq
        sectionHeading={faq.sectionHeading}
        description={faq.description}
        accordion={faq.accordion}
        config={DEFAULT_PADDING}
      />}

      <ThreePostsRow
        sectionHeading={postRecommendations.sectionHeading}
        defaultPosts={allWpPost.nodes}
        selectedPosts={postRecommendations.selectedPosts}
        config={DEFAULT_PADDING}
      />

      <BrandsCarouselVariant2
        sectionHeading={moreBrandsSlider.sectionHeading}
        text={moreBrandsSlider.text}
        brands={moreBrandsSlider.brands || defaultMoreBrands}
        config={DEFAULT_END_SLIDER_PADDING}
      />
    </>
  )
}

export default BrandView
