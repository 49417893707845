import { graphql } from "gatsby"
import React, { useState } from "react"

import { getDynamicProductLink } from "../../../utils/productsHelper"
import Layout from "../../../components/Layout"
import ProductFilters from "../ProductFilters"
import BrandView from "./BrandView"
import {makePageSchema} from "../../../utils/seoHelper";

const Brand = ({ location, pageContext,  data: acfData  }) => {
  const displayProducts = JSON.parse(pageContext.displayProducts)
  const breadcrumbData = [{ name: "Home", path: "/" }, { name: acfData?.wpPaBrand?.name, path: location.pathname }]

  const seoHeadTags = pageContext?.seo?.fullHead
  const rawSchema = pageContext?.seo?.schema?.raw
  const pageSchema = rawSchema ? makePageSchema(rawSchema) : null
  const [data, setData] = useState({
    static: true,
    filters: {},
    products: null,
    fetching: false,
    page: 0,
    sortBy: 0,
    initStaticView: false,
  })

  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <a
      href=""
      ref={ref}
      onClick={e => {
        e.preventDefault()
        onClick(e)
      }}>
      {children}
    </a>
  ))

  const [mobileToggle, setMobileToggle] = useState(false)

  const toggleMenu = e => {
    e.preventDefault()
    setMobileToggle(!mobileToggle)
  }

  /**
   *  Filters
   */
  const Filters = () => (
    <ProductFilters
      pageContext={pageContext}
      productData={[data, setData]}
      location={location}
    />
  )

  const handleClearFilters = () => {
    setData({
      ...data,
      static: true,
      filters: {},
      products: null,
      fetching: false,
      page: 0,
      sortBy: 0,
    })
  }

  const handlePageChange = page => {
    setData({ ...data, static: false, page: page - 1, fetching: true })
  }

  const handleSelectSort = eventKey => {
    setData({
      ...data,
      static: false,
      fetching: true,
      sortBy: Number(eventKey),
    })
  }

  return (
    <Layout
      staticSeo={{
        seoTitle: `${pageContext.name} product list | ${process.env.ORG_TRADING_NAME}`,
        seoDescription: `${pageContext.name} product list | ${process.env.ORG_TRADING_NAME}`,
      }}
      seoHeadTags={seoHeadTags}
      schema={pageSchema}
      location={location}>
      <BrandView
        location={location}
        products={displayProducts}
        data={data}
        pageContext={pageContext}
        breadcrumbData={breadcrumbData}
        Filters={Filters}
        mobileToggle={mobileToggle}
        toggleMenu={toggleMenu}
        handleClearFilters={handleClearFilters}
        handlePageChange={handlePageChange}
        handleSelectSort={handleSelectSort}
        CustomToggle={CustomToggle}
        getDynamicProductLink={getDynamicProductLink}
        acfData={acfData}
      />
    </Layout>
  )
}

export default Brand

export const pageQuery = graphql`
  query GET_BRAND_PAGE_CB($id: String!) {
    wpPaBrand(id: { eq: $id }) {
      id
      name
      uri
      link
        productCategoryHeader {
          headerContent {
            ... on WpPaBrand_Productcategoryheader_HeaderContent_InfoBanner {
              fieldGroupName
              leftSideImage {
                sourceUrl
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      formats: [AUTO, WEBP, AVIF]
                      quality: 75
                      width: 1024
                      placeholder: NONE
                    )
                  }
                }
              }
              rightSideInfo {
                text
                product {
                  ... on WpSimpleProduct {
                    ...SimpleProductQuery
                  }
                  ... on WpVariableProduct {
                    ...VariableProductQuery
                  }
                }
              }
            }
            ... on WpPaBrand_Productcategoryheader_HeaderContent_ContentFields {
              fieldGroupName
              title
              description
              shortLinks {
                label
                slug
                image {
                  altText
                  sourceUrl
                  localFile {
                    childImageSharp {
                      gatsbyImageData(
                        formats: [AUTO, WEBP, AVIF]
                        quality: 75
                        width: 360
                        placeholder: NONE
                      )
                    }
                  }
                }
              }
            }
            ... on WpPaBrand_Productcategoryheader_HeaderContent_ThreeBoxesInRow {
              fieldGroupName
              boxes {
                box {
                  discountCode {
                    ... on WpCoupon {
                      id
                      code
                      dateExpiry
                      description
                      discountType
                      amount
                    }
                  }
                  icon {
                    position
                    image {
                      sourceUrl
                      title
                      altText
                      localFile {
                        childImageSharp {
                          gatsbyImageData(
                            formats: [AUTO, WEBP, AVIF]
                            quality: 75
                            width: 46
                            placeholder: NONE
                          )
                        }
                      }
                    }
                  }
                  text
                  pageLink {
                    title
                    url
                  }
                }
              }
            }
          }
        }
      extendedBrandInfo {
        seoDescription
        mainDescription
        featureList {
          listItem
        }
        largeImage {
          altText
          sourceUrl
          localFile {
            childImageSharp {
              gatsbyImageData(
                formats: [AUTO, WEBP, AVIF]
                width: 1000
                placeholder: NONE
              )
            }
          }
        }
      }
      productBrandPageLayouts {
        brandLayoutsData {
          header {
            subTitle
            subDescription
          }
          helpCallToAction {
            imageBoxes {
              image {
                altText
                sourceUrl
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      formats: [AUTO, WEBP, AVIF]
                      height: 1200
                      placeholder: NONE
                    )
                  }
                }
              }
            }
            sectionHeading
            text
            button {
              url
              target
              title
            }
          }
          faq {
            sectionHeading
            description
            accordion {
              answer
              question
            }
          }
          postRecommendations {
            sectionHeading
            selectedPosts {
              post {
                ... on WpPost {
                  id
                  categories {
                    nodes {
                      name
                    }
                  }
                  link
                  title
                  date
                  postsLayouts {
                    postObject {
                      ... on WpPost_Postslayouts_PostObject_PostContent {
                        blurb
                        mainImage {
                          altText
                          sourceUrl
                          localFile {
                            childImageSharp {
                              gatsbyImageData(
                                formats: [AUTO, WEBP, AVIF]
                                width: 575
                                aspectRatio: 1.5
                                placeholder: NONE
                              )
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          moreBrandsSlider {
            sectionHeading
            text
            brands {
              brand {
                name
                id
                slug
                extendedBrandInfo {
                  logo {
                    altText
                    sourceUrl
                    localFile {
                      childImageSharp {
                        gatsbyImageData(
                          formats: [AUTO, WEBP, AVIF]
                          width: 500
                          placeholder: NONE
                        )
                      }
                    }
                  }
                  smallImage {
                    altText
                    sourceUrl
                    localFile {
                      childImageSharp {
                        gatsbyImageData(
                          formats: [AUTO, WEBP, AVIF]
                          width: 500
                          placeholder: NONE
                        )
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    allWpPost(limit: 3) {
      nodes {
        ...PostDataQuery
      }
    }
    allWpPaBrand(limit: 9){
      nodes {
        name
        id
        slug
        extendedBrandInfo {
          logo {
            altText
            sourceUrl
            localFile {
              childImageSharp {
                gatsbyImageData(
                  formats: [AUTO, WEBP, AVIF]
                  width: 500
                  placeholder: NONE
                )
              }
            }
          }
          smallImage {
            altText
            sourceUrl
            localFile {
              childImageSharp {
                gatsbyImageData(
                  formats: [AUTO, WEBP, AVIF]
                  width: 500
                  placeholder: NONE
                )
              }
            }
          }
        }
      }
    }

    helpCtaImg1: file(name: { eq: "brand-page-default-help-img1" }) {
      name
      childImageSharp {
        gatsbyImageData(
          formats: [AUTO, WEBP, AVIF]
          width: 1200
          quality: 75
          placeholder: NONE
        )
      }
    }
    helpCtaImg2: file(name: { eq: "brand-page-default-help-img2" }) {
      name
      childImageSharp {
        gatsbyImageData(
          formats: [AUTO, WEBP, AVIF]
          width: 1200
          quality: 75
          placeholder: NONE
        )
      }
    }
  }
`
